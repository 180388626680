* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}


@font-face {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
}


body {
    font-family: 'Roboto', sans-serif;
    color: #565656;
}

.icon-div {
  display: flex;
  align-items: center;
}

.circle-border {
  padding: 10px;
  border-radius: 60px;
  display: flex;
}

.icon-margin {
  padding: 1px 6px 0 0;
  border-radius: 60px;
  display: flex;
}


.circle-border svg {
  height: 24px;
  width: 24px;
}

@media only screen and (max-width: 480px) {
  .circle-border svg {
    height: 20px;
    width: 20px;
  }

  .icon-margin svg {
    height: 18px !important;
    width: 18px !important;
  }
}

.icon-margin svg {
  height: 20px;
  width: 20px;
}

.bg-lightblue {
  background-color: #1DAEAA;
  color: white;
}

.bg-darkyellow {
  background-color: #FB9D11;
  color: white;
}

.bg-gray {
  background-color: #ECECEC;
}

.bg-lightgray {
  background-color: #F6F6F6;
}

.bg-required {
  color: red;
  margin-left: 2px;
}

.m-r-small {
  margin-right: 0.5rem;
}

.m-r-xsmall {
  margin-right: 0.3rem;
}

.m-l-small {
  margin-left: 0.5rem;
}

.active {
  color: #233C60;
}

.active button {
  text-decoration: underline;
}

.main {
  color: #233C60 !important;
  font-weight: 600 !important;
}

.display-none {
  display: none;
}

.container {
  display: inline-block;
  cursor: pointer;
}

.header-mobile {
  background-color: transparent;
  width: 100%;
  padding: 4rem;

}

.contact-mobile {
  padding-bottom: 3.5rem;

  @media only screen and (max-width: 890px) {
    padding-bottom: 0;
  }
}

.bar1, .bar2, .bar3 {
  width: 30px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.react-datepicker-wrapper {
  display: flex !important;
  width: 48%;
}

.od-datepicker-date,
.od-datepicker-time {
  font-family: "Poppins",sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 1rem;
  border: 1px solid #A2A2A2;
  border-radius: 10px;
  color: #565656;
  width: 100%;
}

.no-overflow {
  overflow: hidden;
}
